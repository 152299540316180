import {
  faEnvelope,
  faMapPin,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import BounceLoader from "react-spinners/BounceLoader"
import Layout from "../components/layout"
import SEO from "../components/seo"

// TODO: Google iframe needs to be more responsive for mobile

const Tab = ({ children, active = false, className, ...props }) => (
  <button
    {...props}
    className={`${className} font-medium text-xl ${
      active
        ? "border-b-4 border-blue-800 hover:text-gray-600"
        : "text-gray-400 hover:text-gray-800 hover:border-blue-600 -mt-1"
    } px-2 py-1`}
  >
    {children}
  </button>
)

const DeliveryTime = ({ day, delivery, pickUp }) => (
  <div className="flex justify-between mb-4 border-b border-color-gray-200 p-2 text-gray-800">
    <div className="text-xl font-medium">{day}</div>
    <div className="flex flex-col w-1/2 items-end">
      <div className="text-gray-700">Delivery: {delivery}</div>
      <div className="text-sm text-gray-500">Pick-up: {pickUp}</div>
    </div>
  </div>
)

const DineInTime = ({ day, time }) => (
  <div className="flex justify-between mb-4 border-b border-color-gray-200 p-2 text-gray-800">
    <div className="text-xl font-medium">{day}</div>
    <div className="text-gray-700">{time}</div>
  </div>
)

const Location = () => {
  const [tabId, setTabId] = useState("dineIn")
  const [loading, setLoading] = useState(true)

  const loaded = () => setLoading(false)

  return (
    <>
      <Layout>
        <SEO title="Locations" />

        <div className="flex justify-center">
          <div className="container my-12 flex justify-between">
            <div className="rounded-lg shadow-md hidden lg:inline-block">
              {loading && (
                <div className="flex items-center justify-center h-full">
                  <BounceLoader size={150} color="#1E40AF" />
                </div>
              )}
              <iframe
                title="google maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3546.346688094965!2d-80.32132854864429!3d27.27101198289105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dee8fcd7fcffad%3A0x89c919a73a6424a2!2sLefty&#39;s%20Wings%20%26%20Grill!5e0!3m2!1sen!2sus!4v1606685114472!5m2!1sen!2sus"
                width="800"
                height="100%"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                onLoad={loaded}
              ></iframe>
            </div>
            <div className="w-full lg:w-1/2 text-gray-800 p-2 md:p-0 lg:ml-6 flex flex-col justify-between">
              <h2 className="text-xl font-bold tracking-wide leading-loose mb-4">
                Contact Us
              </h2>
              <div className="mb-4 ml-4 flex items-center">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="text-2xl mr-4 text-blue-800"
                />{" "}
                <a
                  className="text-lg hover:text-blue-800"
                  href="tel:+17723373212"
                >
                  +1 (772) 337-3212
                </a>
              </div>
              <div className="flex items-center ml-4">
                <FontAwesomeIcon
                  icon={faMapPin}
                  className="text-2xl text-blue-800 mr-4"
                />
                <address className="text-lg ml-4">
                  <span className="not-italic text-lg font-medium">
                    Google Map Directions:
                  </span>
                  <a
                    href="https://goo.gl/maps/cXHjp7xzPrVKf1QP6"
                    className="hover:text-blue-800 block"
                  >
                    Lefty's Wings & Grill
                    <br /> 1034 SE Port St Lucie Blvd Port St. Lucie, FL 34952
                  </a>
                </address>
              </div>
              <div className="my-6 border-b border-color-gray-400 py-4">
                <h5 className="text-xl font-bold text-gray-800 mb-4 tracking-wide leading-loose">
                  Message Us
                </h5>
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="contact" value="contact" />
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Your Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 px-2"
                        placeholder="John Doe"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 px-2"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Message
                    </label>
                    <div className="mt-1">
                      <textarea
                        name="message"
                        id="message"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 px-2 h-20"
                        placeholder="Your message here..."
                      />
                    </div>
                  </div>
                  <div className="my-6 flex justify-end">
                    <button
                      type="submit"
                      className="bg-blue-700 hover:bg-blue-800 text-medium text-white px-6 py-2 rounded-lg shadow"
                    >
                      <FontAwesomeIcon icon={faEnvelope} /> Send
                    </button>
                  </div>
                </form>
              </div>
              <div className="bg-white mt-6 p-4">
                <h4 className="text-3xl mb-6 text-center">Store Hours</h4>
                <div className="flex justify-around">
                  <Tab
                    active={tabId === "dineIn"}
                    onClick={() => setTabId("dineIn")}
                  >
                    Dine-in
                  </Tab>
                  <Tab
                    active={tabId === "delivery"}
                    onClick={() => setTabId("delivery")}
                  >
                    Delivery / Pick-up
                  </Tab>
                </div>
                <div className="mt-4">
                  <AnimatePresence exitBeforeEnter>
                    {tabId === "dineIn" && (
                      <motion.div
                        key="dineIn"
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        <DineInTime day="Sunday" time="11:30am-10:00pm" />
                        <DineInTime day="Monday" time="11:30am-10:00pm" />
                        <DineInTime day="Tuesday" time="11:30am-10:00pm" />
                        <DineInTime day="Wednesday" time="11:30am-10:00pm" />
                        <DineInTime day="Thursday" time="11:30am-11:00pm" />
                        <DineInTime day="Friday" time="11:30am-12:00am" />
                        <DineInTime day="Saturday" time="11:30am-11:00pm" />
                      </motion.div>
                    )}
                    {tabId === "delivery" && (
                      <motion.div
                        key="delivery"
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        <DeliveryTime
                          day="Sunday"
                          delivery="11:30am-9:30pm"
                          pickUp="11:30am-10:00pm"
                        />
                        <DeliveryTime
                          day="Monday"
                          delivery="11:30am-9:30pm"
                          pickUp="11:30am-10:00pm"
                        />
                        <DeliveryTime
                          day="Tuesday"
                          delivery="11:30am-9:30pm"
                          pickUp="11:30am-10:00pm"
                        />
                        <DeliveryTime
                          day="Wednesday"
                          delivery="11:30am-9:30pm"
                          pickUp="11:30am-10:00pm"
                        />
                        <DeliveryTime
                          day="Thursday"
                          delivery="11:30am-10:00pm"
                          pickUp="11:30am-11:00pm"
                        />
                        <DeliveryTime
                          day="Friday"
                          delivery="11:30am-10:00pm"
                          pickUp="11:30am-6:00pm"
                        />
                        <DeliveryTime
                          day="Saturday"
                          delivery="11:30am-10:00pm"
                          pickUp="11:30am-11:00pm"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Location
